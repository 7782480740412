import YMaps from 'ymaps';

/**
 * items: [ {
 *    id, coordinates,
 *    title, address, color
 * } ]
 */

export default () => {
  const maps = window.maps || [];
  maps
    .map(({ id: mapId, center: mapCenter, items: mapItems = [] }) => ({
      id: mapId,
      center: mapCenter.split(',').map((item) => Number(item)),
      items: mapItems.map(
        ({
          id,
          coordinates,
          url,
          pagetitle,
          photo,
          address,
          worktime,
          phone,
          preset,
          presetHover,
        }) => ({
          id,
          coordinates: coordinates.split(',').map((item) => Number(item)),
          url,
          pagetitle,
          photo,
          address,
          worktime,
          phone,
          tel: phone?.replace(/[^+\d]/gm, ''),
          preset: preset || 'islands#blackIcon',
          presetHover: presetHover || 'islands#nightIcon',
        }),
      ),
    }))
    .forEach((map) => {
      const rootNode = document.querySelector(`#${map.id}`);
      if (!rootNode) return;

      YMaps.load('https://api-maps.yandex.ru/2.1/?lang=ru_Ru').then((ymaps) => {
        const yandexMap = new ymaps.Map(map.id, {
          center: map.center,
          zoom: map.zoom || 10,
          controls: map.controls || ['zoomControl'],
        });

        const clusterer = new ymaps.Clusterer({
          preset: 'islands#blackClusterIcons',
          clusterHideIconOnBalloonOpen: false,
          geoObjectHideIconOnBalloonOpen: false,
        });

        clusterer.events
          // Можно слушать сразу несколько событий, указывая их имена в массиве.
          .add(['mouseenter', 'mouseleave'], (e) => {
            const target = e.get('target');
            const type = e.get('type');
            if (typeof target.getGeoObjects !== 'undefined') {
              // Событие произошло на кластере.
              if (type === 'mouseenter') {
                target.options.set('preset', 'islands#nightClusterIcons');
                return;
              }

              target.options.set('preset', 'islands#blackClusterIcons');
            } else {
              // Событие произошло на метке.
              if (type === 'mouseenter') {
                target.options.set('preset', target.placemark.presetHover);
                return;
              }

              target.options.set('preset', target.placemark.preset);
            }
          })
          .add('click', (e) => {
            const target = e.get('target');
            if (typeof target.getGeoObjects === 'undefined') {
              // Событие произошло на метке.
              console.log(target);
            }
          });

        rootNode.map = yandexMap;
        rootNode.items = {};

        const placemarks = map.items || [];

        placemarks.forEach((placemark) => {
          const Placemark = new ymaps.Placemark(placemark.coordinates, null, {
            preset: placemark.preset,
          });
          Placemark.placemark = placemark;

          clusterer.add(Placemark);
          rootNode.items[placemark.id] = Placemark;
        });

        yandexMap.geoObjects.add(clusterer);

        const centerAndZoom = ymaps.util.bounds.getCenterAndZoom(
          clusterer.getBounds(),
          yandexMap.container.getSize(),
          yandexMap.options.get('projection'),
        );

        yandexMap.setCenter(
          centerAndZoom.center,
          centerAndZoom.zoom > 15 ? 15 : centerAndZoom.zoom,
        );
      });
    });
};
