import ymap from './modules/ymap';

const app = () => {
  ymap();

  jQuery('.jet-sorting-select').change(() => {
    setTimeout(() => {
      const newHref = location.href;
      console.log(newHref);
      window.location.href = newHref;
    }, 100);
  });
};

export default app;
